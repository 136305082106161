import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";
import fr from "vuetify/es5/locale/fr"

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en, es, fr },
        current: process.env.VUE_APP_I18N_LOCALE || "en",
      },
});
