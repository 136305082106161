/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
    {
        icon: "mdi-view-dashboard",
        text: i18n.t("menu.dashboard"),
        link: "/",
    },
    { divider: true },admin.getResourceLink("seasons"),admin.getResourceLink("episodes"),admin.getResourceLink("activities"),admin.getResourceLink("tags"),admin.getResourceLink("characters"),admin.getResourceLink("comments"),
    admin.getResourceLink("users"),
];
