<template>
  <va-create-layout>
    <episodes-form :title="title" :item="item"></episodes-form>
  </va-create-layout>
</template>

<script>
export default {
  name: "EpisodeCreate",
  props: ["title", "item"],
};
</script>
