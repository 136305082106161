<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-boolean-input source="status"></va-boolean-input>
            <va-text-input source="title"></va-text-input>
            <va-select-input
              source="episode_id"
              reference="episodes"
              label="Episodes"
              item-text="title"
              :itemsPerPage="null"
            ></va-select-input>
            <va-select-input source="type" :choices="choices"></va-select-input>
            <va-text-input source="description" multiline></va-text-input>
            <va-text-input source="url"></va-text-input>
            <va-select-input
              source="tags"
              reference="tags"
              label="Tags"
              item-text="name"
              multiple
              :itemsPerPage="null"
            ></va-select-input>
            <va-file-input
              source="activity"
              preview
              lg="4"
              label="Activity file"
            >
            </va-file-input>
            <va-file-input
              source="thumbnail"
              preview
              lg="4"
              label="Thumbnail"
            >
            </va-file-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  name: 'ActitiviesForm',
  props: ["id", "title", "item"],
  data() {
    return {
      choices: [
        { value: 'drawings', text: 'Drawing' },
        { value: 'crafts', text: 'Craft' },
        { value: 'games', text: 'Game' }
      ]
    }
  }
};
</script>
