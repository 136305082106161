export default [
    { name: "users", icon: "mdi-account", routes: ["list"] },
    {
        name: "seasons",
        translatable: true,
    },
    { name: "episodes", translatable: true },
    { name: "activities", translatable: true },
    { name: "tags", translatable: true },
    { name: "characters", translatable: true },
    { name: "comments" },
];
