<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list
      :filters="filters"
    >
      <va-data-table :fields="fields">
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  name: "EpisodeList",
  props: ["resource", "title"],
  data() {
    return {
      filters: ["title", "publication_date"],
      fields: [
        { source: "title", sortable: true },
        { source: "publication_date", type: "date", sortable: true },
      ],
    };
  },
};
</script>
