<template>
    <va-input>
      <v-color-picker
        dot-size="25"
        flat
        hide-mode-switch
        mode="hexa"
        :value="color"
        @blur.native="change"
        @input="update"
      ></v-color-picker>
    </va-input>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";

export default {
  mixins: [Input],
  props: {
    /**
     * Number to be edited.
     * @model
     */
    color: {
      type: String,
      default: "#FF0000FF",
    },
  },
};
</script>