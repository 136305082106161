<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-boolean-input source="status"></va-boolean-input>
            <va-select-input
              source="season_id"
              reference="seasons"
              label="Season"
              item-text="title"
              :itemsPerPage="null"
            ></va-select-input>
            <va-text-input source="title"></va-text-input>
            <va-number-input source="number" :step="1" :min="0" :max="99"></va-number-input>
            <va-text-input source="description" multiline></va-text-input>
            <va-text-input source="url"></va-text-input>
            <va-select-input
              source="tags"
              reference="tags"
              label="Tags"
              item-text="name"
              multiple
              :itemsPerPage="null"
            ></va-select-input>
            <va-date-input source="publication_date"></va-date-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  name: "EpisodeForm",
  props: ["id", "title", "item"],
  data() {
    return {
      episodes: null,
    }
  },
  methods: {

  }
};
</script>
